<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">map</i>
            </div>
            <h4 class="card-title">
              <strong>{{ $t("data.map.title") }}</strong>
            </h4>
          </div>
          <div class="card-body mt-3">
            <div class="row m-0">
              <div class="col-md-5 col-sm-12 order-last text-left">
                <div
                  style="
                    padding: 0px;
                    color: red;
                    font-size: 18px;
                    align-items: top;
                    left: 0;
                  "
                >
                  <b style="font-size: 26px">{{ asset_out_herd() }}</b>
                  {{ $t("data.map.labels.outGeofence") }}
                </div>
              </div>
              <div class="col-md-2 col-sm-12 text-center">
                <div
                  style="
                    color: green;
                    font-size: 3rem;
                    width: 7rem;
                    height: 7rem;
                    border-style: solid;
                    border-color: darkolivegreen;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                  "
                >
                  <b>{{ asset_in_herd() }}</b>
                </div>
              </div>
              <div class="col-md-5 col-sm-12 order-first text-right">
                <div
                  style="
                    color: green;
                    font-size: 18px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  "
                >
                  {{ $t("data.map.labels.inGeofence") }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <select
              @change="change($event)"
              class="select p-1"
              v-show="!isEditing"
            >
              <option value="0">
                {{ $t("data.map.fields.buttons.filter") }}
              </option>
              <option
                v-for="(herd, index) in herds"
                :key="index"
                :value="herd.id"
              >
                {{ herd.name }}
              </option>
            </select>
            <div class="p-2">
              <bootstrap-alert />
            </div>
            <div class="topButtonsContainer" v-show="isEditing">
              <button class="topButtons btn btn-primary" @click="save">
                {{ $t("data.map.fields.buttons.save") }}
              </button>
              <button class="topButtons btn btn-grey" @click="cancel">
                {{ $t("data.map.fields.buttons.exit") }}
              </button>
            </div>
          </div>
          <div>
            <div class="row justify-content-center align-content-center">
              <div class="col-md-12">
                <div class="card p-4 justify-content-center align-items-center">
                  <div class="loading" v-show="loading">
                    <material-spinner class="loadingSpinner" />
                  </div>
                  <l-map
                    style="height: 70vh"
                    ref="map"
                    :zoom="mapSettings.zoom"
                    :center="mapCenter"
                  >
                    <l-tile-layer
                      :url="mapSettings.url"
                      :attribution="mapSettings.attribution"
                    />
                    <l-control>
                      <div class="mapButtonsContainer">
                        <button
                          class="btn btn-white p-2"
                          v-show="canEdit && !isEditing"
                          @click="openEditMode"
                        >
                          {{ $t("data.map.fields.buttons.edit") }}
                        </button>
                        <button
                          class="btn btn-white p-2"
                          v-show="isEditing"
                          @click="deleteCreated()"
                        >
                          {{ $t("data.map.fields.buttons.reset") }}
                        </button>
                      </div>
                    </l-control>
                    <l-control v-show="isEditing" position="bottomleft">
                      <div class="mapButtonsContainer">
                        <button
                          class="btn btn-white p-2"
                          :class="{
                            selected: mode == 1,
                          }"
                          @click="changeMode(1)"
                        >
                          {{ $t("data.map.fields.buttons.draw") }}
                        </button>
                        <button
                          class="btn btn-white p-2"
                          :class="{
                            selected: mode == 0,
                          }"
                          @click="changeMode(0)"
                        >
                          {{ $t("data.map.fields.buttons.move") }}
                        </button>
                      </div>
                    </l-control>
                    <l-marker
                      :visible="!loading"
                      v-for="asset in activeAssets"
                      :key="(asset.id * 5) / 2"
                      :lat-lng="asset.coordinates.coordinates"
                    >
                      <l-popup>
                        <strong>
                          {{ $t("data.map.fields.asset.name") }}
                        </strong>
                        {{ asset.name }} <br />
                        <strong>
                          {{ $t("data.map.fields.asset.last_update") }}
                        </strong>
                        {{ asset.coordinates.updated_at }}
                        <br />
                        <strong>
                          {{ $t("data.map.fields.asset.latitude") }}
                        </strong>
                        {{ asset.coordinates.coordinates[0] }}
                        <br />
                        <strong>
                          {{ $t("data.map.fields.asset.longitude") }}
                        </strong>
                        {{ asset.coordinates.coordinates[1] }}
                        <br />
                      </l-popup>
                      <l-icon
                        class-name="assetHistoryMarkerIcon"
                        :icon-size="dynamicSize"
                        :icon-anchor="dynamicAnchor"
                      >
                        <div class="assetHistoryMarkerIcon">
                          {{ asset.sequential_number }}
                        </div>
                      </l-icon>
                    </l-marker>
                    <l-polygon
                      :visible="!isEditing"
                      v-for="herd in activeAndWithPolygonHerds"
                      :key="herd.id"
                      :lat-lngs="[herd.geofence.coordinates]"
                      :color="herd.hexa_color"
                      :fill="false"
                    />
                    <l-freedraw
                      :v-model="newHerdGeoFence"
                      ref="lFreedraw"
                      :mode="mode"
                      :options="{ maximumPolygons: 1 }"
                    ></l-freedraw>
                  </l-map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BootstrapAlert from "../../components/BootstrapAlert.vue";

export default {
  components: { BootstrapAlert },
  data() {
    return {
      mapSettings: {
        lng: 12.76,
        url:
          "https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}@2x.jpg?key=Cru7v9WzfK586J1qCfLl",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 8
      },
      canEdit: false,
      isEditing: false,
      mode: 0,
      iconSize: 15,
      newHerdGeoFence: []
    };
  },
  beforeDestroy() {
    this.resetState();
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler() {
        this.resetState();
        this.fetchData().then(() => {
          this.$refs.map.mapObject.panTo(this.mapCenter);
        });
      }
    }
  },
  computed: {
    ...mapGetters("MapIndex", [
      "herds",
      "assets",
      "loading",
      "activeHerds",
      "activeAssets",
      "mapCenter"
    ]),
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.1];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.1];
    },
    /**
     * Filters the herds with a pre-set geofence, in order to avoid errors
     */
    activeAndWithPolygonHerds: function() {
      return this.activeHerds.filter(herd => {
        return herd.geofence ? true : false;
      });
    }
  },
  methods: {
    ...mapActions("MapIndex", [
      "fetchData",
      "resetState",
      "setActive",
      "resetActive",
      "setNewHerdGeoFence",
      "createNewHerdGeoFence"
    ]),
    change(event) {
      if (event.target.value == 0) {
        this.resetActive();
        this.canEdit = false;
      } else {
        this.setActive(event.target.value);
        this.canEdit = true;
      }
    },
    openEditMode() {
      this.isEditing = true;
      this.mode = 1;
      this.$refs.lFreedraw.mapObject.on("markers", event => {
        if (event.eventType == "create") {
          this.newHerdGeoFence = event.latLngs;
        }
      });
    },
    save() {
      if (this.newHerdGeoFence.length > 0) {
        if (confirm("Sei sicuro? I dati precedenti verranno eliminati!")) {
          this.isEditing = false;
          this.canEdit = false;
          this.mode = 0;
          this.$refs.lFreedraw.mapObject.clear();
          if (
            !this.activeHerds[0].geofence ||
            this.activeHerds[0].geofence.coordinates.length == 0
          ) {
            this.createNewHerdGeoFence({
              geoFence: this.newHerdGeoFence[0],
              herdId: this.activeHerds[0].id
            }).catch(() => {
              this.resetActive();
              this.fetchData();
            });
          } else {
            this.setNewHerdGeoFence({
              geoFence: this.newHerdGeoFence[0],
              herdId: this.activeHerds[0].id
            }).catch(() => {
              this.resetActive();
              this.fetchData();
            });
          }
          this.resetActive();
        }
      } else {
        alert("Non puoi salvare un recinto vuoto!");
      }
    },
    cancel() {
      if (confirm("Sei sicuro? Le modifiche non salvate verranno eliminate!")) {
        this.isEditing = false;
        this.mode = 0;
        this.newHerdGeoFence = [];
        this.$refs.lFreedraw.mapObject.clear();
      }
    },
    changeMode(mode) {
      this.mode = mode;
    },
    deleteCreated() {
      this.$refs.lFreedraw.mapObject.clear();
      this.newHerdGeoFence = [];
    },
    asset_in_herd() {
      return this.activeHerds.reduce((totalSum, herd) => {
        let finalValue =
          herd.asset_in_herd != undefined ? herd.asset_in_herd.length : 0;
        return totalSum + finalValue;
      }, 0);
    },
    asset_out_herd() {
      return this.activeHerds.reduce((totalSum, herd) => {
        let finalValue =
          herd.asset_out_herd != undefined ? herd.asset_out_herd.length : 0;
        return totalSum + finalValue;
      }, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
  border: 0;
  border-radius: $border-radius-large;
  color: $gray-dark;
  background: $white-color;
  @include box-shadow($bmd-shadow-2dp);
}
.loading {
  position: absolute;
  display: flex;
  z-index: 100000000;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    130deg,
    rgba($sidebar-color1, 1) 11%,
    rgba($sidebar-color2, 1) 77%
  );
}
.loadingSpinner {
  position: absolute;
}
.mapButtonsContainer {
  display: flex;
  justify-content: center;
  align-content: center;
}
.selected {
  background-color: $brand-primary !important;
  color: $white-color !important;
}
.topButtonsContainer {
  display: flex;
  justify-content: center;
  align-content: center;
}
.topButtons {
  background: $white;
  margin: 0 0.5em;
  border: 0;
  border-radius: $border-radius-large;
  color: $gray-dark;
  background: $white-color;
  @include box-shadow($bmd-shadow-2dp);
}
.assetHistoryMarkerIcon {
  background-color: $color-rose;
  color: $white-color;
  border: 1px solid #333;
  border-radius: 20px;
  font-size: auto;
  font-weight: bold;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  min-width: 20px !important;
  width: fit-content !important;
  height: 20px !important;
  margin: 0 !important;
}
</style>
